<template>
  <div>
    <v-form id="seasonSet">
      <v-card style="border-radius:15px; overflow: hidden;">
        <div class="title">{{season_setData.building_name}} {{season_setData.season_id ? "계절구간 수정" : "계절구간 추가"}}</div>

        <div class="season_contents">
          <v-row>
            <v-subheader class="season_nametxt mr-1" style="width:110px;"> 계절명 </v-subheader>
            <v-text-field dense outlined 
              type="text"
              id="season_name"
              ref="season_name"
              class="season_name shrink"
              v-model="season_name"/>
          </v-row>
          <v-row class="season_date">
                <v-subheader class="season_datetxt" style="width:110px;"> 구간 시점 </v-subheader>
                <v-layout wrap ma-1>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="10"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense outlined class="shrink fromdate mr-1 mt-1" style="height: 40px; width: 120px;"
                        id="season_from" 
                        ref="season_from" 
                        :value="fromMD"
                        label="부터"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title locale="ko-KR"
                      v-model="season_from"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                  <!-- <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="-120"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense outlined class="shrink todate mr-1 mt-1" style="height: 40px; width: 100px;"
                        id="season_to" 
                        ref="season_to" 
                        :value="toMD"
                        label="까지"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title locale="ko-KR"
                      v-model="season_to"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu> -->
                </v-layout>
              </v-row>

              <v-row>
                <v-subheader class="season_memo mt-3" style="width:110px;"> 비고 </v-subheader>
                <v-textarea class="ml-1 mt-3 mb-2" outlined dense rows="2" style="max-width: 190px"
                  id="memo" 
                  ref="memo" 
                  v-model="memo"
                  value=""
                  clearable
                  persistent-hint :hint="history"
                />
              </v-row>
        </div>

        <v-row class="footer justify-center">
              <v-btn outlined class="exit_btn mr-5" style="height:50px; width:80px;" 
              @click="$emit('close')">취소</v-btn>
              <v-btn outlined class="save_btn mr-5" 
              @click="save()" style="height:50px; width:80px;">적용</v-btn>
              <v-btn outlined class="delete_btn mr-5" v-show="season_setData.season_id" 
              @click="deleteSeason()" style="height:50px; width:80px;">삭제</v-btn>
        </v-row>
      </v-card>

      </v-form>
  </div>
</template>

<script>
import Apis from '@/api/apis';


export default {
  name: 'SeasonSet',

  props: {
    season_setData: {
      type: Object,
    },
  },
  data (){
    return{
      season_name:this.season_setData.season_name,
      season_from:this.season_setData.season_from,
      // season_to:this.season_setData.season_to,
      memo:this.season_setData.description,
      history:this.season_setData.history,
      
      menu1:false,
      menu2:false,
    }
  },

  watch:{
    season_setData: function(){
      this.season_name = this.season_setData.season_name;
      this.season_from = this.season_setData.season_from;
      // this.season_to = this.season_setData.season_to;
      this.memo = this.season_setData.description;
      this.history = this.season_setData.history;
    }
  },
  computed: {
    fromMD() {
      return this.season_from
        ? this.season_from.substr(5,5)
        : "";
    },
    // toMD() {
    //   return this.season_to
    //     ? this.season_to.substr(5,5)
    //     : "";
    // }
  },
  methods : {
    clearData(){
      this.season_name = "";
      this.season_from = "";
      // this.season_to = "";
      this.memo = "";

    },

    checkData(){
      if(!this.season_name.trim()){
        this.$refs.season_name.focus();
        return "계절명을 입력하세요";
      }
      if(!this.season_from){
        this.$refs.season_from.focus();
        return "계절구간을 선택하세요";
      }
      // if(!this.season_to){
      //   this.$refs.season_to.focus();
      //   return "계절구간을 선택하세요";
      // }
      // if (this.toMD < this.fromMD) {
      //   this.$refs.season_to.focus();
      //   return "구간 범위를 정확히 입력하세요";
      // }
      let result = "";
      this.$emit("checkDuplicate",        // 계절시작일 중복 확인
                  this.season_setData.season_id,
                  this.fromMD,
                  (res) => {result = res; this.$refs.season_from.focus();}
      );
      return result;
    },
    save(){
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }
      this.loadingSave = true;
      Apis.saveSeasonRange({
        "id" : this.season_setData.season_id,
        "company_cd" : this.season_setData.company_cd,
        "farm_cd" : this.season_setData.farm_cd,
        "building_cd" :	this.season_setData.building_cd,
        "season_name" :	this.season_name.trim(),
        "season_from" : this.fromMD,
        // "season_to" : this.toMD,
        "season_to" : "",
        "description" :	this.memo.trim(),
      },(res) => {  
          if (res.result) {
            this.$store.commit("resMessage",res.message);
            this.loadingSave = false;
            this.clearData();
            this.$emit("refresh");
          } else {
            this.loadingSave = false;
            console.log("저장 오류",res.message)
          }
        }).catch( (err) => {  // API 오류 처리
            this.loadingSave = false;
            console.log("saveSeasonRange API 호출 오류",err)
            alert(err);
        }
      )
    },
    deleteSeason(){
      if (!confirm("경고 ! \n 삭제 하시겠습니까 ? \n 삭제 후 복구할 수 없습니다, 주의 요망")) {
        return
      }
      Apis.deleteSeasonRange(
        {
          id: Number(this.season_setData.season_id),
        },
        res => {
          this.$store.commit("resMessage",res.message);
          if (res.result) {
            this.clearData();
            this.$emit("refresh");
          }
        }
      ).catch(err =>
          {  // API 오류 처리
            console.log("deleteSeasonRange API 호출 오류",err)
            alert(err);
          }
        )  
    },
    }
  }
</script>
  
  
<style lang="scss" scoped >
.title{
  justify-content:left;
  font-weight:bold !important;
  font-size:18px;
  padding:25px;
}

.season_contents{
  border-radius: 10px !important;
  padding:20px 5px 6px 7px;
  margin:10px;
  margin-left: 20px;
  margin-top:-10px;
  border:#c5c5c5 1px solid;
  border-radius: 10px;
}

.season_nametxt, .season_datetxt, .season_memo{
  margin-left: 5px;
  font-size:16px;
}


.season_date{
  margin-top:-5px;
}

.footer{
  margin-bottom: 20px;
  margin-top:10px;
}

.exit_btn, .modify_btn{
  border-color: #3a4f3f !important;
  color:#3a4f3f !important;
  font-size:18px !important;
  border-radius: 25px !important;
  font-weight: bold !important;
  letter-spacing: 1px;
}

.save_btn{
  background-color: #3a4f3f !important;
  color:white !important;
  font-size:18px !important;
  border-radius: 25px !important;
  font-weight: bold !important;
  letter-spacing: 1px;
}

.delete_btn{
  background-color: #88191a !important;
  color:white !important;
  font-size:18px !important;
  border-radius: 25px !important;
  font-weight: bold !important;
  letter-spacing: 1px;
}


</style>