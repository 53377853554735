<template>
  <div class="dialog">
    
    <v-form id="weightSet">
      <v-card height="auto" style="border-radius:15px; overflow: hidden;">
        <div class="title"> {{ setData.building_name }} {{ setData.weight_id ? "체중구간 수정" : "체중구간 추가" }}</div>

        <div class="weight_contents">
           <div style="display:flex;">
              <v-subheader  class="weight_nametxt " style="width: 30%;"> 구간 명 </v-subheader>
              <v-text-field dense outlined style="width: 65%;"
                type="text"
                id="weight_name"
                ref="weight_name"
                class="shrink"
                v-model="weight_name"/>
          </div>

          <div style="display:flex; margin-top: -15px;">
            <v-subheader class="weight_section" style="width: 30%;"> 체중 </v-subheader>
            <v-text-field dense outlined  
              type="number" 
              id="weight_min"
              ref="weight_min"
              label="부터"
              class="weight_min shrink mr-1"
              v-model="weight_min"/>
            <span class="weight_unit mr-5">㎏</span>

            <v-text-field dense outlined 
              type="number"
              id="weight_max"
              ref="weight_max"
              label="까지"
              class="weight_max shrink"
              v-model="weight_max"/>
            <span class="weight_unit2">㎏</span>
          </div>

          <div style="display:flex; margin-top: -15px;">
            <v-subheader class="weight_section" style="width: 30%;"> 주령 </v-subheader>
            <v-text-field dense outlined  
              type="number" 
              id="age_min"
              ref="age_min"
              label="부터"
              class="weight_min shrink mr-1"
              v-model="age_min"/>
            <span class="weight_unit mr-5">주</span>

            <v-text-field dense outlined 
              type="number"
              id="age_max"
              ref="age_max"
              label="까지"
              class="weight_max shrink"
              v-model="age_max"/>
            <span class="weight_unit2">주</span>
          </div>

          
          <div style="display:flex; margin-top: -15px;">
            <v-subheader class="weight_memo" style="width: 30%;"> 비고 </v-subheader>
            <v-textarea class="mr-4 mb-2" outlined dense rows="2" name="input-7-4" style="width:65%;"
              id="memo" 
              ref="memo" 
              v-model="memo"
              value=""
              clearable
              persistent-hint :hint="history"
            />
          </div>
        </div>
      
          <v-row class="footer justify-center">
            <v-btn outlined class="exit_btn mr-2" 
            @click="$emit('close')" style="height:50px; width:80px;">취소</v-btn>
            <v-btn outlined class="save_btn mr-2" 
            @click="save()" style="height:50px; width:80px;" >적용</v-btn>
            <v-btn outlined class="delete_btn" v-show="setData.weight_id"
            @click="deleteWeight()" style="height:50px; width:80px;" >삭제</v-btn>
          </v-row>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import Apis from '@/api/apis'
import Common from "@/utils/custom/common.js";

export default {
  name: 'WeightSet',

  props: {
    setData: {
      type: Object,
    },
  },

  data (){
    // console.log("reseive",this.setData)
    return{
      weight_name:this.setData.weight_name,
      weight_min:this.setData.weight_min,
      weight_max:this.setData.weight_max,
      age_min:this.setData.age_min,
      age_max:this.setData.age_max,
      memo:this.setData.description,
      history:this.setData.history,
    }
  },
  watch: {
    setData: function() {
      // console.log("watch",this.setData)
      this.weight_name = this.setData.weight_name;
      this.weight_min = this.setData.weight_min;
      this.weight_max = this.setData.weight_max;
      this.age_min = this.setData.age_min;
      this.age_max = this.setData.age_max;
      this.memo = this.setData.description;
      this.history = this.setData.history;
    },
  },

  methods : {
    clearData() {
      this.weight_name = "";
      this.weight_min = 0;
      this.weight_max = 0;
      this.age_min = 0;
      this.age_max = 0;
      this.memo = "";
    }, 
    checkData() {
      if (!this.weight_name.trim()) {
        this.$refs.weight_name.focus();
        return "구간명을 입력하세요";
      }
      if (!Common.isNumeric(this.weight_min) || Number(this.weight_min < 1)) {
        this.$refs.weight_min.focus();
        return "체중 하한을 정확히 입력하세요";
      }
      if (!Common.isNumeric(this.weight_max) || Number(this.weight_max < 1)) {
        this.$refs.weight_max.focus();
        return "체중 상한을 정확히 입력하세요";
      }
      if (Number(this.weight_max) == Number(this.weight_min) || Number(this.weight_max) < Number(this.weight_min)) {
        this.$refs.weight_max.focus();
        return "체중 상한,하한을 정확히 입력하세요";
      }
      if (!Common.isNumeric(this.age_min) || Number(this.age_min) < 1) {
        this.$refs.age_min.focus();
        return "주령 하한을 정확히 입력하세요";
      }
      if (!Common.isNumeric(this.age_max) || Number(this.age_max < 1)) {
        this.$refs.age_max.focus();
        return "주령 상한을 정확히 입력하세요";
      }
      if (Number(this.age_max) == Number(this.age_min) || Number(this.age_max) < Number(this.age_min)) {
        this.$refs.age_max.focus();
        return "주령 상한,하한을 정확히 입력하세요";
      }
      let result = "";
      this.$emit("checkDuplicate",        // 구간명,체둥하한,주령하한 중복 확인
                  this.setData.weight_id,
                  this.weight_name,
                  this.weight_min,
                  this.age_min,
                  (res) => {result = res; this.$refs.weight_name.focus();}
      );
      return result;
    },
    save() {
      // 구간저장 process
      this.$store.commit("resMessage","");
      
      let msgCheckdata = this.checkData(); 
      // console.log("check", msgCheckdata);
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }

      this.loadingSave = true;
      Apis.saveWeightRange({
        "id" : this.setData.weight_id,
        "company_cd": this.setData.company_cd,
        "farm_cd" : this.setData.farm_cd,
        "building_cd" :	this.setData.building_cd,
        "weight_name" :	this.weight_name.trim(),
        "weight_min" :	Number(this.weight_min),
        "weight_max" :	Number(this.weight_max),
        "age_min" :	Number(this.age_min),
        "age_max" :	Number(this.age_max),
        "description" :	this.memo.trim(),
        },(res) => {  
          if (res.result) {
            this.$store.commit("resMessage",res.message);
            this.loadingSave = false;
            this.clearData();
            this.$emit("refresh");
          } else {
            this.loadingSave = false;
            console.log("저장 오류",res.message)
          }
        }).catch( (err) => {  // API 오류 처리
            this.loadingSave = false;
            console.log("saveWeightRange API 호출 오류",err)
            alert(err);
        }
      )
    },
    deleteWeight() {
      if (!confirm("경고 ! \n 삭제 하시겠습니까 ? \n 삭제 후 복구할 수 없습니다, 주의 요망")) {
        return
      }  
      Apis.deleteWeightRange(
        {
          id: Number(this.setData.weight_id),
        },
        res => {
          this.$store.commit("resMessage",res.message);
          if (res.result) {
            this.clearData();
            this.$emit("refresh");
          } else {
            alert(res.message);
          }
        }
      ).catch(err =>
          {  // API 오류 처리
            console.log("deleteWeightRange API 호출 오류",err)
            alert(err);
          }
        )
    },

  }
}
</script>
  
  
<style lang="scss" scoped >

.title{
  justify-content:left;
  font-weight:bold !important;
  font-size:18px;
  padding:20px;
}

.weight_contents{
  width:auto;
  padding:15px 15px 1px 10px;
  margin:25px;
  margin-top:-10px;
  border:#c5c5c5 1px solid;
  border-radius: 10px;
}
.footer{
  padding-bottom: 30px;
  padding-top:10px;
}

.weight_stand{
  margin-top:-20px;
}

.weight_nametxt, .weight_memo, .weight_section{
  margin-top: -4px;
  font-size:16px !important;
}

.birth_min, .birth_max, .weight_min, .weight_max{
  width: 70px;
}

.weight_unit{
  // margin-left:-15px;
  margin-top:10px;
  font-size:13px;
  color:grey;
}

.weight_unit2{
  margin-left:5px;
  margin-top:10px;
  font-size:13px;
  color:grey;
}
.exit_btn{
  border-color: #3a4f3f !important;
  color:#3a4f3f !important;
  font-size:18px !important;
  border-radius: 25px !important;
  font-weight: bold !important;
  letter-spacing: 1px;
}

.save_btn{
  background-color: #3a4f3f !important;
  color:white !important;
  font-size:18px !important;
  border-radius: 25px !important;
  font-weight: bold !important;
  letter-spacing: 1px;
}
.delete_btn{
  background-color: #88191a !important;
  color:white !important;
  font-size:18px !important;
  border-radius: 25px !important;
  font-weight: bold !important;
  letter-spacing: 1px;
}


</style>