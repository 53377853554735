<template>
  <v-container >
    <v-form id="controllerSet">
      <v-card style="border-radius:15px; overflow: hidden;">
      <div>
        <div style="font-size:20px; padding:13px; font-weight:bold"> {{control_setData.season_name}} / {{control_setData.weight_name}} {{control_setData.fan.set_temp1=== 0 ? "추가":"수정"}} </div>
      </div>

        <div class="set_contents">
          <div class="set_title mb-3">팬컨트롤러</div>
          <div>
            <div class="fc_setbox">
              <v-layout wrap class="fc_detail">
                <v-subheader style="font-size:17px; font-weight:bold;" class="mr-2">1차</v-subheader> 
                <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="min_vent1"
                  ref="min_vent1"
                  class="min_vent1 mr-3 centered-input shrink" 
                  label="최소출력"
                  min="0"
                  max="99"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan.min_vent1"/> 

                <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="max_vent1"
                  ref="max_vent1"
                  class="max_vent1 mr-3 centered-input shrink" 
                  label="최대출력"
                  min="0"
                  max="99"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan.max_vent1"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="set_temp1"
                  ref="set_temp1"
                  class="set_temp1 centered-input mr-3 shrink" 
                  label="설정온도"
                  min="15"
                  max="33"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan.set_temp1"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="dev_temp1"
                  ref="dev_temp1"
                  class="dev_temp1 centered-input mr-5 shrink" 
                  label="온도편차"
                  min="0"
                  max="10"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan.dev_temp1"/>
              </v-layout>
            </div>
          </div>
          
          <div dense class="fc_setbox">
            <v-layout wrap class="fc_detail">
              <v-subheader style="font-size:17px; font-weight:bold;" class="mr-2">2차</v-subheader>
            
              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="min_vent2"
                  ref="min_vent2"
                  class="min_vent2 centered-input mr-3 shrink" 
                  label="최소출력"
                  min="0"
                  max="99"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan.min_vent2">                    
              </v-text-field>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="max_vent2"
                  ref="max_vent2"
                  class="max_vent2 centered-input mr-3 shrink" 
                  label="최대출력"
                  min="0"
                  max="99"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan.max_vent2"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="set_temp2"
                  ref="set_temp2"
                  class="set_temp2 centered-input mr-3 shrink" 
                  label="설정온도"
                  min="15"
                  max="33"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan.set_temp2"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="dev_temp2"
                  ref="dev_temp2"
                  class="dev_temp2 centered-input mr-5 shrink" 
                  label="온도편차"
                  min="0"
                  max="10"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan.dev_temp2"/>
            </v-layout>
          </div>

          <div dense class="fc_setbox">
            <v-layout wrap class="fc_detail">
              <v-subheader style="font-size:17px; font-weight:bold;" class="mr-2">3차</v-subheader>
            
              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="min_vent3"
                  ref="min_vent3"
                  class="min_vent3 centered-input mr-3 shrink"
                  label="최소출력"
                  min="0"
                  max="99"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan.min_vent3">                    
              </v-text-field>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="max_vent3"
                  ref="max_vent3"
                  class="max_vent3 centered-input mr-3 shrink" 
                  label="최대출력"
                  min="0"
                  max="99"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan.max_vent3"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="set_temp3"
                  ref="set_temp3"
                  class="set_temp3 centered-input mr-3 shrink" 
                  label="설정온도"
                  min="15"
                  max="33"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan.set_temp3"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="dev_temp3"
                  ref="dev_temp3"
                  class="dev_temp3 centered-input mr-5 shrink" 
                  label="온도편차"
                  min="0"
                  max="10"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan.dev_temp3"/>
            </v-layout>
          </div>


          <v-layout>
            <span class="set_title mr-4">보온등</span>
            <v-switch dense v-model="heater.on_off1" style="margin-top:-2px;" color="red darken-4" @change="changeValue()">
              <template #prepend>
                <v-label><span class="lamp_off">OFF</span></v-label>
              </template>
              <template #append>
                <v-label><span class="lamp_on">ON</span></v-label>
              </template>
            </v-switch>
          </v-layout>

          <div dense class="fc_setbox" v-show="heater.on_off1 === true ">
            <v-layout wrap class="heat_detail">
              <v-subheader style="font-size:17px; font-weight:bold;" class="mr-2">1차</v-subheader>
              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="f_min_vent1"
                  ref="min_vent1"
                  class="min_vent1 centered-input mr-3 shrink" 
                  label="최소출력"
                  min="0"
                  max="99"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="heater.min_vent1"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="max_vent1"
                  ref="f_max_vent1"
                  class="max_vent1 centered-input mr-3 shrink" 
                  label="최대출력"
                  min="0"
                  max="99"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="heater.max_vent1"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="set_temp1"
                  ref="f_set_temp1"
                  class="set_temp1 centered-input mr-3 shrink" 
                  label="설정온도"
                  min="15"
                  max="33"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="heater.set_temp1"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="dev_temp1"
                  ref="f_dev_temp1"
                  class="dev_temp1 centered-input mr-5 shrink" 
                  label="온도편차"
                  min="0"
                  max="10"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="heater.dev_temp1"/>
            </v-layout>
          </div>
        </div>


        <v-row class="justify-center mb-4">
          <v-btn outlined class="exit_btn mb-4 mr-3" 
              @click="$emit('close')" style="height:50px; width:110px;">취소</v-btn>
          <v-btn outlined class="save_btn mb-4" 
              @click="save()" style="height:50px; width:110px;">저장</v-btn>
        </v-row>

      </v-card>
    </v-form>
  </v-container>
</template>

<script>
  import Common from "@/utils/custom/common.js";
  import Apis from '@/api/apis';
  import dateUtil from "@/utils/custom/dateProperty.js";

export default {
  name: 'ControllerSet',
  
  props:{
    control_setData : {
      type: Object,
    },

  },

  data(){
    return{
      fan:this.control_setData.fan,
      heater:this.control_setData.heater,

      season_name:this.control_setData.season_name,
      season_id:this.control_setData.season_id,
      weight_name:this.control_setData.weight_name,
      weight_id:this.control_setData.weight_id,
    }
  },

  watch : {
    control_setData : function(){
      this.fan = this.control_setData.fan;
      this.heater = this.control_setData.heater;
      
      this.season_name = this.control_setData.season_name;
      this.season_id = this.control_setData.season_id;
      this.weight_name = this.control_setData.weight_name;
      this.weight_id = this.control_setData.weight_id;
      
    }
  },

  methods : {
    checkData(){
      // console.log(this.control_setData.fan,this.control_setData.heater);
      if (!Common.isNumeric(this.fan.max_vent1) || this.fan.max_vent1 < 0 || this.fan.max_vent1 > 100 ) {
            this.$refs.max_vent1.focus();
            return "정확한 값을 입력하세요(0~99)";
      }
      if (!Common.isNumeric(this.fan.min_vent1) || this.fan.min_vent1 < 0 || this.fan.min_vent1 > 100 ) {
        this.$refs.min_vent1.focus();
        return "정확한 값을 입력하세요(0~99)";
      }
      if (!Common.isNumeric(this.fan.set_temp1)|| this.fan.set_temp1 < 15 || this.fan.set_temp1 > 33) {
        this.$refs.set_temp1.focus();
        return "정확한 값을 입력하세요(15~33)";
      }
      if (!Common.isNumeric(this.fan.dev_temp1)|| this.fan.dev_temp1 < 0 || this.fan.dev_temp1 > 10) {
        this.$refs.dev_temp1.focus();
        return "정확한 값을 입력하세요(0~10)";
      }
      if (!Common.isNumeric(this.fan.max_vent2) || this.fan.max_vent2 < 0 || this.fan.max_vent2 > 100 ) {
            this.$refs.max_vent2.focus();
            return "정확한 값을 입력하세요(0~99)";
      }
      if (!Common.isNumeric(this.fan.min_vent2) || this.fan.min_vent2 < 0 || this.fan.min_vent2 > 100) {
        this.$refs.min_vent2.focus();
        return "정확한 값을 입력하세요(0~99)";
      }
      if (!Common.isNumeric(this.fan.set_temp2) || this.fan.set_temp2 < 15 || this.fan.set_temp2 > 33) {
        this.$refs.set_temp2.focus();
        return "정확한 값을 입력하세요(15~33)";
      }
      if (!Common.isNumeric(this.fan.dev_temp2)|| this.fan.dev_temp2 < 0 || this.fan.dev_temp2 > 10) {
        this.$refs.dev_temp2.focus();
        return "정확한 값을 입력하세요(0~10)";
      }
      if (!Common.isNumeric(this.fan.max_vent3)|| this.fan.max_vent3 < 0 || this.fan.max_vent3 > 100 ) {
            this.$refs.max_vent3.focus();
            return "정확한 값을 입력하세요(0~99)";
      }
      if (!Common.isNumeric(this.fan.min_vent3) || this.fan.min_vent3 < 0 || this.fan.min_vent3 > 100 ) {
        this.$refs.min_vent3.focus();
        return "정확한 값을 입력하세요(0~99)";
      }
      if (!Common.isNumeric(this.fan.set_temp3)|| this.fan.set_temp3 < 15 || this.fan.set_temp3 > 33) {
        this.$refs.set_temp3.focus();
        return "정확한 값을 입력하세요(15~33)";
      }
      if (!Common.isNumeric(this.fan.dev_temp3) || this.fan.dev_temp3 < 0 || this.fan.dev_temp3 > 10) {
        this.$refs.dev_temp3.focus();
        return "정확한 값을 입력하세요(0~10)";
      }

      if(this.heater.on_off1 === true) {
        if (!Common.isNumeric(this.heater.max_vent1)|| this.heater.max_vent1 < 0 || this.heater.max_vent1 >100 ) {
              this.$refs.f_max_vent1.focus();
              return "정확한 값을 입력하세요(0~99)";
        }
        if (!Common.isNumeric(this.heater.min_vent1)|| this.heater.min_vent1 < 0 || this.heater.min_vent1 >100 ) {
          this.$refs.f_min_vent1.focus();
          return "정확한 값을 입력하세요(0~99)";
        }
        if (!Common.isNumeric(this.heater.set_temp1) || this.heater.set_temp1 < 15 || this.heater.set_temp1 > 33) {
          this.$refs.f_set_temp1.focus();
          return "정확한 값을 입력하세요(15~33)";
        }
        if (!Common.isNumeric(this.heater.dev_temp1) || this.heater.dev_temp1 < 0 || this.heater.dev_temp1 > 10) {
          this.$refs.f_dev_temp1.focus();
          return "정확한 값을 입력하세요(0~10)";
        }
      }
      return "";
    },

    changeValue(){
      this.$store.commit("resMessage","");
    },

    save(){
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }
      this.loadingSave = true;

      Apis.saveControlRangeSettings({
        "company_cd" : this.control_setData.company_cd,
        "farm_cd" : this.control_setData.farm_cd,
        "building_cd" : this.control_setData.building_cd,
        "fan" : this.control_setData.fan,
        "heater" : this.control_setData.heater,
        "history" : "",
        "season_id" : this.control_setData.season_id,
        "weight_id" : this.control_setData.weight_id,
        "regdate" : dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        },(res) => {
          if (res.result) {
            this.$store.commit("resMessage",res.message);
            this.loadingSave = false;
            this.$emit("refresh");

          } else {
            this.loadingSave = false;
            console.log("저장 오류",res.message)
          }
        }).catch( (err) => {  // API 오류 처리
            this.loadingSave = false;
            console.log("saveControllerSetting API 호출 오류",err)
            alert(err);
        }
      )
    },

  }
}

</script>

<style lang="scss" scoped >
  .set_contents{
    padding:15px;
    margin-top:-15px;
    
  }
  
  .fc_setbox{
  width:auto;
  margin-top:-10px;
  margin-bottom: 20px;
  margin-left:5px;
  border-radius: 10px;
  display: flex;
  box-shadow: 0 1px 2px #a3a3a3;
}

.fc_detail{
  padding:5px;
  margin-top:5px;
  margin-bottom:-15px;
}

.heat_detail{
  padding:5px;
  margin-top:5px;
  margin-bottom:-15px;
}

.max_vent1, .min_vent1, .set_temp1, .dev_temp1, .max_vent2, .min_vent2, .set_temp2, .dev_temp2, .max_vent3, .min_vent3, .set_temp3, .dev_temp3{
  width:80px;
}

.set_title{
  font-weight: bold;
  font-size:18px !important;
  color:grey !important;
  margin-left:10px;
}

.exit_btn{
  border-color: #3a4f3f !important;
  color:#3a4f3f !important;
  font-size:18px !important;
  border-radius: 30px !important;
  font-weight: bold !important;
  letter-spacing: 1px;
}
.save_btn{
  background-color: #3a4f3f !important;
  color:white !important;
  font-size:18px !important;
  border-radius: 30px !important;
  font-weight: bold !important;
  letter-spacing: 1px;
}
</style>